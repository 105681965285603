<template>
  <div class="fullwidth vh-100 h-auto" style="position: relative">

    <div class="trading-dashboard-sidebar h-100 bg-warning" :class="{'show-side-bar': sideBarOpen}">
      <div class="text-right hidden-sm hidden-md hidden-lg">
        <x-icon size="1x" class="text-white pointer m-2" @click="closeSideBar()"></x-icon>
      </div>
      <div class="logo-wrapper">
        <router-link to="/trading/main">
          <img src="/assets/remittance-assets/img/transcrypt-logo-white.png" alt="TransCrypt logo"
               title="TransCrypt" class="p-3">
        </router-link>
      </div>

      <p style="color:#369F4F;letter-spacing: -0.46px;font-weight: bold" class="text-left mt-2 ml-2 mb-2">Crypto
        Trading</p>

      <div style="
            background: #369F4F;
            opacity: 1;
            width: 100%;
            padding:20px;
            ">
        <div style="display:flex;flex-direction: column; justify-content:center; align-items: center">
          <div style="color:#ffffff;font-size:0.7em;align-self: flex-start;letter-spacing: -0.25px;">Your crypto
            portfolio
          </div>
          <div style="color:#ffffff;font-size:1.5em;align-self: flex-start;letter-spacing: -0.53px;">SGD
            <strong>{{ formatFiatPrice(userCryptoPortfolioValue) }}</strong></div>
        </div>
      </div>

      <ul class="trading-dashboard-sidebar-lists">
        <li class="trading-dashboard-list-style" @click="goToPortfolioPage()"
            :class="{'text-selection' : this.url === '/trading/main'}">
          <home-icon size="1.5x" class="mr-1"></home-icon>
          <span>Home</span>
        </li>
        <li class="trading-dashboard-list-style" @click="goToTransactionHistoryPage()"
            :class="{'text-selection' : this.url === '/trading/history'}">
          <bar-chart2-icon size="1.5x" class="mr-1"></bar-chart2-icon>
          <span>History</span>
        </li>
        <!--        <li class="trading-dashboard-list-style" @click="goToProfilePage()"-->
        <!--            :class="{'text-selection' : this.url === '/trading/profile'}">-->
        <!--          <user-icon size="1.5x" class="mr-1"></user-icon>-->
        <!--          <span>Profile</span>-->
        <!--        </li>-->
        <li class="trading-dashboard-list-style" @click="goToWithdrawalPage()"
            :class="{'text-selection' : this.url === '/trading/withdraw'}">
          <dollar-sign-icon size="1.5x" class="mr-1"></dollar-sign-icon>
          <span>Withdraw</span>
        </li>
        <!--        <li class="trading-dashboard-list-style" @click="goToSettingsPage()"-->
        <!--            :class="{'text-selection' : this.url === '/trading/settings'}">-->
        <!--          <settings-icon size="1.5x" class="mr-1"></settings-icon>-->
        <!--          <span>Settings</span>-->
        <!--        </li>-->
        <!--        <li class="trading-dashboard-list-style" @click="goToLogoutPage()">-->
        <!--          <log-out-icon size="1.5x" class="mr-1"></log-out-icon>-->
        <!--          <span>Logout</span>-->
        <!--        </li>-->
        <!--        <li class="trading-dashboard-list-style" @click="goToRedeemPage()">-->
        <!--          <div style="display: flex;flex-direction: column">-->
        <!--            <div>Redeem</div>-->
        <!--            <img src="/assets/trading-assets/grab-rewards-logo.svg"/>-->
        <!--          </div>-->
        <!--        </li>-->

        <!--        <li class="trading-dashboard-list-style" @click="goToTransCryptCashOut()" >-->
        <!--          <div style="display: flex;flex-direction: column">-->
        <!--            <div class="text-white text-small">Switch to</div>-->
        <!--            <small class="text-white" style="border:1px solid white;padding:5px;border-radius: 5px;text-align: center;margin:3px 0">TransCrypt CashOut / Send Money</small>-->
        <!--          </div>-->
        <!--        </li>-->
      </ul>
      <div class="switch-flow-wrapper">
        <div style="display: flex;flex-direction: column">
          <div class="text-large fw-700" style="color:#369F4F">Switch to</div>
          <div class="switch-flow-inner-container pointer"
               @click="goToTransCryptCashOut()">
            <small class="text-white text-small">TransCrypt Cash Out / Send Money</small>
            <chevron-right-icon size="1.5x" class="text-white"></chevron-right-icon>
          </div>
        </div>
      </div>
    </div>
    <div class="trading-dashboard-main vh-100 h-auto w-xs-100 dashboard-bg">
      <div class="dashboard-main-nav bg-info flex-left-right p-4">
        <div class="flex-row-align-center">
          <menu-icon size="1.5x" class="hidden-md hidden-sm hidden-lg pointer mr-1"
                     @click="openSideBar()"></menu-icon>
        </div>
        <div class="trading-dashboard-child-nav w-100">
          <vs-row vs-w="12" vs-type="flex" vs-justify="flex-start" vs-align="center">
            <vs-col vs-w="3" vs-type="flex" vs-justify="flex-start" vs-align="center">
              <div class="trading-flex">
                <arrow-left-icon v-if="showBackButton" size="1.5x" class="trading-text-black m-1 pointer"
                                 @click="goBack()"></arrow-left-icon>
                <cryptoicon v-if="$route.params.cryptocurrency"
                            :symbol="$route.params.cryptocurrency.toLowerCase()"
                            size="30"
                            class="m-1"></cryptoicon>
                <div class="trading-flex-column pt-1 pb-1">
                  <div class="ml-1-xs text-medium" style="line-height: 11px"><small>{{ pageTitleSoft }}</small></div>
                  <div class="ml-1-xs trading-text-black text-large"><strong>{{ pageTitleBold }}</strong></div>
                </div>
              </div>
            </vs-col>
            <!--            user menu for lg screen-->
            <vs-col vs-w="9" vs-type="flex" vs-justify="flex-end" vs-align="center" class="pos-rel" v-if="showUsername">
              <div class="pointer trading-flex" @click="showTradingUserMenuSmall()">
                <span class="mr-1">{{ username && username }}</span>
                <chevron-down-icon size="1x"></chevron-down-icon>
              </div>
              <div class="trading-user-menu-small">
                <div class="text-medium pt-1 pointer" @click="goToProfilePage()">
                  <user-icon size="1x" class="mr-1"></user-icon>
                  Profile
                </div>
                <div class="text-medium pt-1 pointer" @click="goToSettingsPage()">
                  <settings-icon size="1x" class="mr-1"></settings-icon>
                  Settings
                </div>
                <div class="text-medium pt-1 pointer" @click="goToLogoutPage()">
                  <log-out-icon size="1x" class="mr-1"></log-out-icon>
                  Logout
                </div>
              </div>
            </vs-col>
            <!--            user menu for small screen-->
            <vs-col vs-w="9" vs-type="flex" vs-justify="flex-end" vs-align="center" class="pos-rel" v-else>
              <chevron-down-icon size="2x" style="border-radius: 50px" class="p-1 bg-white pointer"
                                 @click="showTradingUserMenuSmall()"></chevron-down-icon>
              <div class="trading-user-menu-small">
                <small v-if="windowInnerWidth < 900" class="pb-1 fw-600"
                       style="border-bottom:1px solid lightgrey">{{ username }}</small>
                <div class="pt-1">
                  <div class="text-medium pt-1 pointer" @click="goToProfilePage()">
                    <user-icon size="1x" class="mr-1"></user-icon>
                    Profile
                  </div>
                  <div class="text-medium pt-1 pointer" @click="goToSettingsPage()">
                    <settings-icon size="1x" class="mr-1"></settings-icon>
                    Settings
                  </div>
                  <div class="text-medium pt-1 pointer" @click="goToLogoutPage()">
                    <log-out-icon size="1x" class="mr-1"></log-out-icon>
                    Logout
                  </div>
                </div>

              </div>
            </vs-col>
          </vs-row>
        </div>
      </div>


      <div class="dashboard-main-content h-100 p-3">
        <main class="h-100 w-100">
          <router-view></router-view>
        </main>

        <!-- Start Footer Area -->
        <!--TODO hide on mobile-->

        <!-- End Footer Area -->
      </div>

    </div>
    <!--    <footer class="dashboard-main-footer">-->
    <!--      <div class="footer-area-bottom">-->
    <!--        <div class="container">-->
    <!--          <div class="row">-->
    <!--            <div class="col-md-12 col-sm-12 col-xs-12">-->
    <!--              <div class="copyright">-->
    <!--                <p>-->
    <!--                  ©2021-->
    <!--                  <span class="text-success"><strong>TransCrypt</strong></span> All Rights Reserved.-->
    <!--                  Contact us at <a class="text-success"-->
    <!--                                   href="mailto:support@transcryptglobal.com?subject=Crypto%20Transfer%20Enquiry">support@transcryptglobal.com</a>-->
    <!--                  if you have questions.-->
    <!--                </p>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </footer>-->

  </div>
</template>
<script>
import {
  HomeIcon,
  SettingsIcon,
  LogOutIcon,
  MenuIcon,
  XIcon,
  ChevronDownIcon,
  BarChart2Icon,
  DollarSignIcon,
  UserIcon,
  ArrowLeftIcon,
    ChevronRightIcon
} from 'vue-feather-icons'
import {mapActions, mapGetters} from 'vuex'

export default {
  components: {
    HomeIcon,
    SettingsIcon, LogOutIcon,
    MenuIcon, XIcon,
    ChevronDownIcon,
    BarChart2Icon,
    DollarSignIcon,
    UserIcon,
    ArrowLeftIcon,
    ChevronRightIcon
  },
  data() {
    return {
      sideBarOpen: false,
      url: "",

      pageTitleSoft: "",
      pageTitleBold: "",

      username: "",
      showUsername: false,

      windowInnerWidth: null,

      toggleTradingUserMenuLarge: false,
      toggleTradingUserMenuSmall: false,

      showBackButton: false,


    }
  },
  created() {
    window.addEventListener("resize", () => {
      this.windowInnerWidthHandler()
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", () => {
      this.windowInnerWidthHandler()
    });
  },
  watch: {
    $route(to, from) {
      if (to.path === '/trading/main') {
        this.url = to.path;
        this.pageTitleSoft = 'Welcome to';
        this.pageTitleBold = 'TransCrypt';
        this.showBackButton = false;
      } else if (to.path === '/trading/history') {
        this.url = to.path;
        this.pageTitleSoft = 'Transaction';
        this.pageTitleBold = 'History';
        this.showBackButton = true;
      } else if (to.path.indexOf('/trading/withdraw') >= 0) {
        this.url = to.path;
        this.pageTitleSoft = 'Withdraw';
        this.pageTitleBold = 'To Bank';
        this.showBackButton = true;
      } else if (to.path === '/trading/settings') {
        this.url = to.path;
        this.pageTitleSoft = '';
        this.pageTitleBold = 'Settings';
        this.showBackButton = true;
      } else if (to.path === '/trading/profile') {
        this.url = to.path;
        this.pageTitleSoft = '';
        this.pageTitleBold = 'Profile';
        this.showBackButton = true;
      } else if (to.path.indexOf('/trading/buy') >= 0) {
        this.url = to.path;
        this.pageTitleSoft = 'Buy';
        this.pageTitleBold = this.$route.params.cryptocurrency.toUpperCase();
        this.showBackButton = true;
      } else if (to.path.indexOf('/trading/sell') >= 0) {
        this.url = to.path;
        this.pageTitleSoft = 'Sell';
        this.pageTitleBold = this.$route.params.cryptocurrency.toUpperCase();
        this.showBackButton = true;
      } else if (to.path === '/trading/redeem-voucher') {
        this.url = to.path;
        this.pageTitleSoft = '';
        this.pageTitleBold = 'Redeem';
      }

    },
    url: function (newVal, oldVal) {

      if (newVal === '/trading/main') {

        this.pageTitleSoft = 'Welcome to';
        this.pageTitleBold = 'TransCrypt';
        this.showBackButton = false;
      } else if (newVal === '/trading/history') {

        this.pageTitleSoft = 'Transaction';
        this.pageTitleBold = 'History';
        this.showBackButton = true;
      } else if (newVal.indexOf('/trading/withdraw') >= 0) {

        this.pageTitleSoft = 'Withdraw';
        this.pageTitleBold = 'To Bank';
        this.showBackButton = true;
      } else if (newVal === '/trading/settings') {

        this.pageTitleSoft = '';
        this.pageTitleBold = 'Settings';
        this.showBackButton = true;
      } else if (newVal === '/trading/profile') {

        this.pageTitleSoft = '';
        this.pageTitleBold = 'Profile';
        this.showBackButton = true;
      } else if (newVal.indexOf('/trading/buy') >= 0) {

        this.pageTitleSoft = 'Buy';
        this.pageTitleBold = this.$route.params.cryptocurrency.toUpperCase();
        this.showBackButton = true;
      } else if (newVal.indexOf('/trading/sell') >= 0) {

        this.pageTitleSoft = 'Sell';
        this.pageTitleBold = this.$route.params.cryptocurrency.toUpperCase();
        this.showBackButton = true;
      } else if (newVal === '/trading/redeem-voucher') {

        this.pageTitleSoft = '';
        this.pageTitleBold = 'Redeem';
      }
    }
  },
  computed: {
    ...mapGetters(["userFiatBalanceGetter", "userCryptoBalanceGetter", "userDetailsGetter"]),
    userCryptoPortfolioValue() {
      let userCryptoPortfolioValue = this.userCryptoBalanceGetter && this.userCryptoBalanceGetter.reduce((accumulator, item) => accumulator + Number(item.port_val), 0)
      return userCryptoPortfolioValue;
    }

  },
  mounted() {
    let url = new URL(window.location);

    this.url = url.pathname;
    // console.log("Trading layout", this.url);


    //get the user crypto portfolio value
    // console.log("User Fiat Balance getter in sidebar", this.userFiatBalanceGetter);
    // console.log("User Crypto Balance getter in sidebar", this.userCryptoBalanceGetter);

    //show username based on the window width
    this.windowInnerWidth = window.innerWidth;
    if (this.windowInnerWidth < 900) {
      this.showUsername = false;
    } else {
      this.showUsername = true;
    }

    this.username = this.userDetailsGetter && this.userDetailsGetter.email;


  },
  methods: {
    ...mapActions(["submitTransferType"]),
    windowInnerWidthHandler() {
      this.windowInnerWidth = window.innerWidth;

      //if window width < 900 px, replace username with an icon
      if (this.windowInnerWidth < 900) {
        this.showUsername = false;
      } else {
        this.showUsername = true;
      }
    },

    showTradingUserMenuLarge() {
      // this.toggleTradingUserMenuLarge = !this.toggleTradingUserMenuLarge;
      // if (this.toggleTradingUserMenuLarge) {
      //   document.querySelector('.trading-user-menu-large').style.visibility = 'visible';
      // } else {
      //   document.querySelector('.trading-user-menu-large').style.visibility = 'hidden';
      // }
      this.toggleUserMenuSmall();
    },

    showTradingUserMenuSmall() {
      // console.log("Clicked");
      // this.toggleTradingUserMenuSmall = !this.toggleTradingUserMenuSmall;
      //
      // if (this.toggleTradingUserMenuSmall) {
      //   document.querySelector('.trading-user-menu-small').style.visibility = 'visible';
      // } else {
      //   document.querySelector('.trading-user-menu-small').style.visibility = 'hidden';
      // }

      this.toggleUserMenuSmall();
    },


    goToLogoutPage() {
      this.$router.push('/trading/user/logout').catch(err => {
      });
      this.sideBarOpen = false;

      this.toggleUserMenuSmall();
      // this.toggleUserMenuLarge();

    },

    goToPortfolioPage() {
      this.$router.push('/trading/main').catch(err => {
      });
      this.sideBarOpen = false;
    },
    goToTransactionHistoryPage() {
      this.$router.push('/trading/history').catch(err => {
      });
      this.sideBarOpen = false;
    },
    goToWithdrawalPage() {
      this.$router.push('/trading/withdraw').catch(err => {
      });
      this.sideBarOpen = false;
    },
    goToSettingsPage() {
      this.$router.push('/trading/settings').catch(err => {
      });
      this.sideBarOpen = false;

      this.toggleUserMenuSmall();
      // this.toggleUserMenuLarge();
    },
    goToRedeemPage() {
      this.$router.push('/trading/redeem-voucher').catch(err => {
      });
      this.sideBarOpen = false;
    },
    goToProfilePage() {
      this.$router.push('/trading/profile').catch(err => {

      })
      this.sideBarOpen = false;

      this.toggleUserMenuSmall();
      // this.toggleUserMenuLarge();
    },
    openSideBar() {
      this.sideBarOpen = true;
      // if (this.debugConsole)
      // console.log("This side bar open", this.sideBarOpen);
    },
    closeSideBar() {
      this.sideBarOpen = false;
      if (this.debugConsole) console.log("This side bar close", this.sideBarOpen);
    },
    goBack() {
      this.$router.go(-1);
    },

    toggleUserMenuSmall() {
      this.toggleTradingUserMenuSmall = !this.toggleTradingUserMenuSmall;

      if (this.toggleTradingUserMenuSmall) {
        document.querySelector('.trading-user-menu-small').style.visibility = 'visible';
      } else {
        document.querySelector('.trading-user-menu-small').style.visibility = 'hidden';
      }
    },

    toggleUserMenuLarge() {
      this.toggleTradingUserMenuLarge = !this.toggleTradingUserMenuLarge;

      if (this.toggleTradingUserMenuLarge) {
        document.querySelector('.trading-user-menu-large').style.visibility = 'visible';
      } else {
        document.querySelector('.trading-user-menu-large').style.visibility = 'hidden';
      }
    },

    goToTransCryptCashOut() {

      this.$router.push('/transfer/remittance-form');

      //adjust the transcrypt type to 'cash-out'
      this.submitTransferType('cash-out');
    },


  }

}
</script>
<style scoped>
img {
  margin-bottom: 20px;
}


.dashboard-bg {
  background-color: #F2F5F7;
}


</style>